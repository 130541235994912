.impressum-wrapper{
    background-color: white;
    width: 50vW;
    margin-left: 25%;
    padding: 2rem;
    border-radius: 20px;
}

.spacerd{
    height: 6rem;
}

.i-heading{
    font-size: 2.2vW;
    font-weight: 600;
}

.spaceri{
    height: 0.7rem;
    background-color: black;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 20vW;
    border-radius: 4px;
}

.spacerd3{
    height: 1rem;
}

.i-subheading{
    font-size: 1.5vW;
    font-weight: 600;
}