*,
*::before,
*::after {
    box-sizing: border-box;
}

.rank{
    position: absolute;
    font-weight: 500;
    top: 0;
    right: 1.4em;
    background: rgba(0, 0, 0, .5);
    padding: .5em .5em .65em;
    font-size: 14px;
}



.btn {
    cursor: pointer;
    border: 0;
    background: rgb(0, 62, 163);
    color: #fff;
    border-radius: 100vw;
    font-weight: 500;
    padding: .5em 1.5em;
    margin-top: 4em;
    margin-bottom: 1em;
}

.img-game{
    border-radius: .5em;
    height: 200px;
}

.img-streamer{
    border-radius: 50%;
    width: 4em;
    height: 4em;
    border: 1px solid rgb(13, 17, 19);
}

.game {
    position: relative;
    width: 12.5em;
    margin-top: 5rem;
    background: #870000;
    color: #fff;
    border-radius: .5em;
    margin-bottom: 5rem;
}

.streamers{
    display: flex;
    margin-bottom: 1rem;
}

.stats{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name{
    text-align: center;
    margin: .75em 0;
}

.front{
    transition: 200ms;
    
}


.back{
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.streaming-info{
    margin: -2em 0;
    columns: 2;
    column-rule: 2px solid rgba(255, 255, 255, .25);
    
}

.game-stat {
    font-size: 1.125rem;
    text-align: center;
}

.span {
    font-size: .9rem;
    display: block;
}

.game:hover .back{
    opacity: 1;
}

.cards-background{
    background: #234;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transform: scale(.2, .9);
    transition: 
        transform 200ms ease-in-out,
        opacity 100ms linear;
    border-radius: var(--br);
}

.game:hover .cards-background{
    transform: scale(1.4, 1.2);
    opacity: 1;
    
}

.game:hover .front{
    transform: translateY(-28%) scale(0.8);
}

.game:hover .name{
    transform: scale(1.3);
}

.num{
    text-align: center;
}