.backgroundvideo{
    /* background-image: url('background.jpg'); */
    height: 85vH;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 90vw;
  }

  video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vW;
    height: 100vH;
    object-fit: cover;
    z-index: -11;
  }

  .home-wrapper{
    /* display: flex;
    flex-direction: column; */
  }

.nav{
    top: 0px;
    position: sticky;
    z-index: 100;
    
}

.ul {
    height: 80px;
    background-color: #870000;
    margin-top: 0;
    list-style: none;
    margin-block-end: 0;
    top: 0;
    position: sticky;
    text-decoration: none;
}

.comp-name{
    color: white;
    font-size: 20px;
    position: absolute;
    padding-top: 1rem;
    text-decoration: none;
    margin-left: 6rem;
    margin-top: -5px;
    height: 40px;
}

.logo{
    color: white;
    z-index: 2;
    
}

.li-produkte {
    margin-left: 65%;
    position: absolute;
    color: white;
    text-decoration: none;
    font-size: 25px;
    padding-top: 1.4rem;
}

.li-produkte :link {
    text-decoration: none;
    color: white;
}

.li-produkte :visited {
    text-decoration: none;
    color: white;
}

.li-produkte :hover {
    
    text-shadow: 0px 0px 15px white;
}

.li-team {
    margin-left: 50%;
    position: absolute;
    color: white;
    text-decoration: none;
    font-size: 25px;
    padding-top: 1.4rem;
}

.li-team :link {
    text-decoration: none;
    color: white;
}

.li-team :visited {
    text-decoration: none;
    color: white;
}

.li-team :hover {
    
    text-shadow: 0px 0px 15px white;
}

.li-events {
    margin-left: 82%;
    position: absolute;
    color: white;
    text-decoration: none;
    font-size: 25px;
    padding-top: 1.4rem;
}

.li-events :link {
    text-decoration: none;
    color: white;
}

.li-events :visited {
    text-decoration: none;
    color: white;
}

.li-events :hover {
    
    text-shadow: 0px 0px 15px white;
}





@media (orientation: portrait) {
    .hero-info-wrapper {
        height: 60vH;
    }
}

:root {
    --bar-width: 60px;
    --bar-height: 8px;
    --hamburger-gap: 6px;
    --foreground: #fff;
    --background: #333;
    --animation-timing: 200ms ease-in-out;
    --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
}

.hamburger-menu{
    --x-width: calc(var(--hamburger-height) * 1.41421356237);

    display: flex;
    flex-direction: column;
    gap: var(--hamburger-gap);
    align-items: end;
    margin-right: 2rem;
    padding-top: 1.4rem;
    z-index: 50;
    cursor: pointer;
   
}

.hamburger-menu::before,
.hamburger-menu::after,
.hamburger-menu input {
    box-sizing: border-box;
    content: "";
    width: var(--bar-width);
    height: var(--bar-height);
    background-color: #ffffff;
    border-radius: 20px;
    transform-origin: left center;
    transition: opacity var(--animation-timing), width var(--animation-timing),
     rotate var(--animation-timing), translate var(--animation-timing);
}

.hamburger-menu input {
    appearance: none;
    padding: 0;
    margin: 0;
    outline: none;
}

.hamburger-menu:has(input:checked)::before {
    rotate: 45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / -2);
}

.hamburger-menu:has(input:checked)::after {
    rotate: -45deg;
    width: var(--x-width);
    translate: 0 calc(var(--bar-height) / 2);
}

.hamburger-menu input:checked{
    /* opacity: 0; */
    width: 0;
}

@media (orientation: landscape) {
    .portrait {
        display: none;
    }
   
}

@media (orientation: portrait) {
    .landscape {
        display: none;
    }
}

.sidebar-hamb{
    transition: translate var(--animation-timing);
    translate: -100%;
    background-color: rgb(49, 49, 49);
    min-height: 100vH;
    min-width: 100vW;
    margin-top: 1.3rem;
    margin-left: -2.5rem;
    color: var(--foreground);
    padding-top: 5rem;
    text-align: center;
    z-index: 90;
}

.hamburger-menu:has(input:checked) + .sidebar-hamb {
    translate: 0%;
}

.li-team-hamb {
    font-size: 45px;
    padding-bottom: 4rem;
    padding-top: 6rem;
}

.li-team-hamb :link {
    text-decoration: none;
    color: white;
}

.li-team-hamb :visited {
    text-decoration: none;
    color: white;
}

.li-produkte-hamb {
    font-size: 45px;
    padding-bottom: 4rem;
}

.li-produkte-hamb :link {
    text-decoration: none;
    color: white;
}

.li-produkte-hamb :visited {
    text-decoration: none;
    color: white;
}

.li-events-hamb {
    font-size: 45px;
    padding-bottom: 2rem;
}

.li-events-hamb :link {
    text-decoration: none;
    color: white;
}

.li-events-hamb :visited {
    text-decoration: none;
    color: white;
}



/* video stop 18:08 */