.background{
    background-image: url('../background.jpg');
    height: 100vH;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .spacert{
    height: .1rem;
  }

.card-img{
    height: 20vW;
    border-radius: 20px;
    margin-top: 2rem;
}



.card-name{
    
    margin-top: 3rem;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 0.5rem;
    margin-left: 0%;
}

.card-rank{
    
    font-size: 18px;
    padding-bottom: 0.4rem;
    margin-left: 0%;
    margin-top: 1rem;
    padding-bottom: 0rem;
}


.card-sales1{
    height: 75vH;
    width: 60vW;
    background-color: #870000;
    margin-left: 20%;
    margin-top: 3rem;
    border-radius: 12px;
    color: white;
    text-align: center;
    
}

.questions-t{
    color: #ebebeb;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.5vW;
}