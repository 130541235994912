.daten-wrapper{
    background-color: white;
    width: 50vW;
    margin-left: 25%;
    padding: 2rem;
    border-radius: 20px;
    padding-bottom: 200px;
}

.daten-content{
    font-size: 1vW;
}

.spacerd{
    height: 6rem;
}

.spacerd2{
    height: 0.7rem;
    background-color: black;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 20vW;
    border-radius: 4px;
}
.spacerd3{
    height: 1rem;
}

.d-heading{
    font-size: 2.2vW;
    font-weight: 600;
}

.d-subheading{
    font-size: 1.5vW;
    font-weight: 600;
}

.d-miniheading{
    font-size: 1.2vW;
    font-weight: 600;
}

.d-microheading{
    font-size: 1.2vW;
}
