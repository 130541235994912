
.hero-info-wrapper{
    background-color: #ffffff;
    min-height: 35.4rem;
    margin-bottom: -135px;
    display: grid;
    justify-content: space-evenly;
}

.hero-headline{
    font-size: 2.5vW;
    text-align: center;
    padding-top: 3rem;
    font-weight: 600;
    margin-bottom: 4rem;
}

.open-offers-wrapper{
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 200px;
}

.open-hours-wrapper{
    margin-left: -12vw;
    align-content: center;
    width: 25vw;
}
.colored-hours-bg{
    background-color: rgb(237, 237, 237);
    width: auto;
    z-index: 2;
    border-radius: 16px;
    min-height: 25vW;  
    padding-bottom: 1rem;
}

.open-times-wrapper{
    width: auto;
    height: 3.3vW;
    text-align: center;
    background-color: #870000;
    border-radius: 16px;
    padding: 0.3rem;
    z-index: 3;
    
}
.open-times{
    font-size: 2vW;
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-align: center;
    margin-top: 0.2vW;
}

.days{
    font-size: 1.4vW;
    padding-top: 1rem;
    margin: auto;
    text-align: center;
    
}
.times{
    font-size: 1.3vW;
    margin-top: 0.2rem;
    text-align: center;
}

.street{
    font-size: 1.5vW;
    margin-top: 1rem;
    text-align: center;
}

.location{
    font-size: 1.3vW;
    text-align: center;
}



.offers-bg-wrapper{
    width: 25vw;
    padding-left: 12vw;
    min-height: 25vw;
}

.offers-bg{
    background-color: rgb(237, 237, 237);
    width: 25vw;
    z-index: 2;
    border-radius: 16px;
    
    min-height: inherit;  
    padding-bottom: 1rem;
}

.offers-wrapper{
    font-weight: 600;
    font-size: 2vW;
    
    width: 25vw;
    height: 3.3vW;
    text-align: center;
    background-color: #870000;
    border-radius: 16px;
    padding: 0.3rem;
    z-index: 3;
    color: white;
    
}

@media (orientation: portrait) {
   

    .offers-bg{
        height: 75%;
    }
}

.offers-spacer{
    height: 3rem;
}

.offers-grouper {
    display: inline-block;
    box-sizing: border-box;
    justify-content: space-evenly;
    column-count: 2;
}

.offers{
    text-align: center;
    font-size: 1.3vW;
    color: black;
    margin-top: 1rem;
}

.offers1{
    text-align: center;
    font-size: 1.3vW;
    color: black;
    margin-top: 0rem;
}