.background-products{
    background-image: url('../background.jpg');
    /* height: 100vW; */
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    
  }

  .background-bottom {
    
    /* background-color: #4D0404; */
  }

  .spacerp{
    height: .1rem;
  }


  @media (orientation: portrait) {
    .specials{
        color: white;
        font-size: 3vW;
        text-align: center;
        padding-top: 2rem;
        font-weight: 600;
        letter-spacing: 1px;
        text-shadow: 0px 0px 50px white;
    }

    .card {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100vW;
        margin-top: 2rem;
        background: rgba(134, 134, 134, 0.6);
    }

    .questions-p{
        color: #ebebeb;
        text-align: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-size: 3vW;
        padding-left: 3rem;
    }
  }

@media (orientation:landscape) {
  .specials{
    color: white;
    font-size: 3vW;
    text-align: center;
    padding-top: 4rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: 0px 0px 50px white;
  }

  .card {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100vW;
    margin-top: 4rem;
    background: rgba(134, 134, 134, 0.6);
    }

    .questions-p{
        color: #ebebeb;
        text-align: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-size: 1.5vW;
        padding-left: 3rem;
    }
}

.card-container{
    display: inline;
    align-items: center;
    width: 100vW;
}



.card-product{
    height: 25vW;
    width: 18vW;
    background-color: #4D0404;
    margin-left: 0rem;
    margin-top: 4rem;
    border-radius: 12px;
    color: white;
    text-align: center;
    align-self: center;
}

.card-name-p{
    padding-top: 110%;
    font-size: 1.5vW;
    font-weight: 600;
    padding-bottom: 0.5rem;
}

.card-rank-p{
    font-size: 18px;
    padding-bottom: 0.4rem;
}

.card-price-p{
    font-size: 1.7rem;
}

.card-product2{
    height: 30vW;
    width: 23vW;
    background-color: #870000;
    margin-left: 3rem;
    margin-top: -30vW;
    border-radius: 12px;
    color: white;
    text-align: center;
    
}

.card-product3{
    height: 30vW;
    width: 23vW;
    background-color: #4D0404;
    margin-left: 3rem;
    margin-top: -24rem;
    border-radius: 12px;
    color: white;
    text-align: center;
    
}

