.footer{
    position: relative;
    display: flexbox;
    bottom: 0;
    align-self: center;
    height: 120px;
    background-color: rgba(29, 29, 29, 0.9);
    /* margin-top: 95%; */
    width: 100vW;
    list-style: none;
    text-decoration: none;
    margin-top: -1.1rem;
    align-content: center;
}

@media (orientation: portrait) {
    .footer {
        position: relative;
        bottom: 0rem;
    }

    .maker{
        font-size: 12px;
        color: white;
        text-align: center;
        margin-top: 0.6rem;
    }

    .maker-link{
        color: white;
        font-size: 12px;
        border: none;
        background-color: transparent;
    }
}

.footer-links-ul{
    padding-top: .4rem;
    text-shadow: 0px 0px 5px #000000;
    align-items: center;
}

@media (orientation: landscape) {
    .maker{
        color: white;
        text-align: center;
        margin-top: 0.6rem;
        font-size: 18px;
        
    }


    .maker-link{
        color: white;
        font-size: 18px;
        border: none;
        background-color: transparent;
    }
}

.datenschutz{
    margin-bottom: 0.4rem;
    text-align: center;
    color: white;
    list-style: none;
    text-decoration: none;
    margin-top: 0.4rem;
}

.datenschutz :visited{
    text-decoration: none;
    color: white;
}

.datenschutz :link{
    text-decoration: none;
    color: white;
}

.impressum{
    text-align: center;
    list-style: none;
    color: white;
}

.impressum :visited{
    text-decoration: none;
    color: white;
}

.impressum :link{
    text-decoration: none;
    color: white;
}