.background{
    background-image: url('../background.jpg');
    height: 100vH;
    background-repeat: no-repeat;
    background-size: cover;
  }

.spacere{
    height: 6rem;
}

.card-event1{
    height: 14rem;
    width: 60%;
    background-color: #870000;
    margin: auto;
    
    border-radius: 60px;
    color: white;
    text-align: center;
}

.event-name{
    padding-top: 2rem;
    font-size: 2vW;
    font-weight: 600;
    padding-bottom: 1.4rem;
    color: #ebebeb;
    margin-left: 8rem;
}

.card-info{
    font-size: 1.2vW;
    padding-bottom: 1rem;
    margin-left: 8rem;
    margin-top: 0.5vW;
}

.card-date{
    font-size: 2.2vW;
    position: absolute;
    margin-top: -7rem;
    margin-left: 5rem;
}

@media (orientation: portrait) {
    .event-name {
        font-size: 3vH;
    }

    .card-info {
        font-size: 2vH;
        margin-top: 1vW;
    }

    .card-time {
        font-size: 2.5vH;
        margin-top: -5rem;
        margin-left: 2vH;
    }
}

.card-product2{
    height: 24rem;
    width: 18rem;
    background-color: #4D0404;
    margin-left: 43%;
    margin-top: -24rem;
    border-radius: 12px;
    color: white;
    text-align: center;
    
}

.card-product3{
    height: 24rem;
    width: 18rem;
    background-color: #4D0404;
    margin-left: 74%;
    margin-top: -24rem;
    border-radius: 12px;
    color: white;
    text-align: center;
    
}

.questions-e{
    color: #ebebeb;
    text-align: center;
    margin-top: 3rem;
    font-size: 2vW;
}